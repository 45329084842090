<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        elevation="0"
        color="grey"
        width="60"
        height="60"
        class="ml-1"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Időszak</h1>

    <v-card :loading="loading">
      <v-card-text>
        <v-text-field
          label="Megnevezés"
          :error-messages="errors.name"
          v-model="form.name"
          filled
        />
        <v-row class="m-1">
          <v-col :cols="12" :md="6">
            <v-datetime-picker
              label="Kezdő dátum"
              :error-messages="errors.start_date"
              :timePicker="false"
              datePicker
              v-model="form.start_date"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-datetime-picker
              label="Záró dátum"
              :error-messages="errors.end_date"
              :timePicker="false"
              datePicker
              v-model="form.end_date"
              filled
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        start_date: "",
        end_date: "",
      },

      errors: [],
      loading: false,
    };
  },

  async mounted() {
    this.fetchDateInverval();
  },

  methods: {
    async fetchDateInverval() {
      try {
        const response = await this.$http.get(`date-intervals/details/${ this.$route.params.id || 0 }`);
        this.form = response.date_interval;
      } catch (e) {
        console.error(e);
      }
    },

    async save(event) {
       try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('date-intervals/save/', this.form);
        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }

        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewDateInterval') {
            this.$router.push({ name: 'DateIntervalEditor', params: { id: response.date_interval.id } });
          } else {
            this.fetchScheduleGroup();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
